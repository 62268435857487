import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'
import i18next from 'i18next'
import { withNamespaces } from 'react-i18next'

const NavBar = ({ history, t }) => {
    const [drawerActive, setDrawerActive] = useState(false)
    const openWindow = (path) => (e) => {
        e.preventDefault()
        if (path.includes('https://')) window.open(path)
        else {
            history.push(path)
            setDrawerActive(false)
            window.scrollTo(0, 0)
        }
    }
    return (
        <nav>
            <div className='navbar-content-drawer'>
                <div className='container flex flex-direction-row align-center justify-between'>
                    <img className='logo pointer' src='/img/cavida-logo.png' alt='logo' onClick={openWindow('/')}/>
                    {/* <h1 className='logo pointer' onClick={openWindow('/')}>CAVIDA</h1> */}
                    <FiMenu className='navbar-icon pointer' onClick={() => setDrawerActive(!drawerActive)} />
                    <div className={`drawer-content ${drawerActive ? 'active' : ''} flex align-center justify-center`}>
                        <Link to='/' className='nav-item-drawer' onClick={openWindow('/')}>Home</Link>
                        <Link to='/products' className='nav-item-drawer' onClick={openWindow('/products')}>{t('navbar.Products')}</Link>
                        <Link to='/our-story' className='nav-item-drawer' onClick={openWindow('/our-story')}>{t('navbar.Our Story')}</Link>
                        <span className='drawer-separator' />
                        <Link to='/' className='nav-item-drawer' onClick={openWindow('https://www.facebook.com/cavidacbd')}>Facebook</Link>
                        <Link to='/' className='nav-item-drawer' onClick={openWindow('https://www.instagram.com/cavidacbd/')}>Instagram</Link>
                    </div>
                </div>
            </div>
            <div className='navbar-content'>
                <img className='logo pointer' src='/img/cavida-logo.png' alt='logo' onClick={openWindow('/')}/>
                {/* <h1 className='logo pointer' onClick={openWindow('/')}>CAVIDA</h1> */}
                <div className='nav-group link-content'>
                    <Link to='/' className='nav-item link no-default' onClick={openWindow('https://www.facebook.com/cavidacbd')}>Facebook</Link>
                    <Link to='/' className='nav-item link no-default' onClick={openWindow('https://www.instagram.com/cavidacbd/')}>Instagram</Link>
                </div>
                <div className='nav-group link-content'>
                    <Link to='/products' className='nav-item link no-default' onClick={openWindow('/products')}>{t('navbar.Products')}</Link>
                    <Link to='/our-story' className='nav-item link no-default' onClick={openWindow('/our-story')}>{t('navbar.Our Story')}</Link>
                </div>
            </div>
        </nav>
    )
}

export default withNamespaces()(withRouter(NavBar))