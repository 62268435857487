import React from 'react'
import { withNamespaces } from 'react-i18next'

const Story = ({ t }) => {
    return (
        <section className='our-story flex justify-center bg-normal'>
            <div className='container'>
                <h1 className='title big'>{t('about.title1-1')}</h1>
                <h2 className='title'>{t('about.title1-2')}</h2>
                <h3>{t('about.subtitle')}</h3>
                <p>{t('about.content-1')}</p>
                <p>{t('about.content-2')}</p>
                <p>{t('about.content-3')}</p>
            </div>
        </section>
    )
}

export default withNamespaces()(Story)