import i18n from 'i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng:'es',
        fallbackLng: 'es',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // react i18next special options (optional)
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
            useSuspense: false //   <---- Or use <Suspense fallback={<div>Loading... </div>}> <App /></Suspense> on Index
        },
    });


export default i18n;