import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../Common/Button'
import { FiMoon, FiSun } from 'react-icons/fi'
import { FaEye, FaBone, FaHeartbeat, FaBrain, FaPlus } from 'react-icons/fa'
import { withNamespaces } from 'react-i18next'
import { Parallax } from 'react-parallax'
import { HashLink } from 'react-router-hash-link'


const Home = ({ history, t }) => {
    const [Y, setY] = useState(window.scrollY)
    const [X, setX] = useState(window.innerWidth)
    const [innerWidth, setInnerWith] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setInnerWith(window.innerWidth)
        })
        /*         window.addEventListener('scroll', (e) => {
                    setY(window.scrollY)
                }) */
        return () => {
        };
    }, []);


    const scrollToHash = (hash) => e => {
        e.preventDefault()
        const element = document.getElementById(hash)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    const sendToProduct = ev => {
        history.push('/products')
    }

    const isMobile = innerWidth <= 700

    return (
        <div className={`${Y < 1200 || Y > 3000 ? 'bg-normal' : (Y < 1800 ? 'bg-day' : 'bg-night')}`}>
            {
                !isMobile && (
                    <header>
                        <Parallax
                            strength={500}
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            bgImage={'/img/Header-Banner.jpg'}
                            bgImageStyle={{
                                //height: '500px',
                                height: '100%',
                                width: '100%',
                                objectFit: 'none',
                                marginTop: '250px',
                            }}
                        >
                            <h1>{t('home.header.welcomeMsg')}</h1>
                            <h1>{t('home.header.mainMsg')}</h1>
                            <p>{t('home.header.secondaryMsg')}.</p>
                            <Button title='Contact' className='cta-btn' onClick={scrollToHash('bottom')} />
                        </Parallax>
                    </header>
                )
            }
            <div className='container'>
                {
                    isMobile && (
                        <header className='flex column justify-center'>
                            <h1>{t('home.header.welcomeMsg')}</h1>
                            <h1>{t('home.header.mainMsg')}</h1>
                            <p>{t('home.header.secondaryMsg')}.</p>
                            <Button title='Contact' className='cta-btn' onClick={scrollToHash('bottom')} />
                            <img src='./img/1.png' alt='' className='header-products header-products-1' />
                            <img src='./img/2.png' alt='' className='header-products header-products-2' />
                            <img src='./img/3.png' alt='' className='header-products header-products-3' />
                        </header>

                    )
                }
                <section className='investment flex column justify-center'>
                    <h1 className='title right'>{t('home.investment.title')}</h1>
                    <p className='content'>
                        {t('home.investment.content')}
                    </p>
                </section>

                <section className='investment' style={{ marginBottom: 20 }}>
                    <Parallax
                        strength={500}
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        bgImage={'/img/CBD-Banner_wooden-table.jpg'}
                        bgImageStyle={{
                            //height: '500px',
                            height: '100%',
                            width: '100%',
                            objectFit: 'none',
                            marginTop: '250px',
                        }}
                    >
                    </Parallax>

                </section>

                <section className='benefits row flex align-center'>
                    <div className='col xs-12'>
                        <h1 className='title center'>{t('home.benefits.title')}</h1>
                    </div>
                    <div className='benefits-content-group col xs-12 md-3 flex align-center'>
                        <FaEye className='icons' />
                        <p className='content'>{t('home.benefits.Eye')}</p>
                    </div>
                    <div className='benefits-content-group col xs-12 md-3 flex align-center'>
                        <FaBrain className='icons' />
                        <p className='content'>{t('home.benefits.Brain')}</p>
                    </div>
                    <div className='benefits-content-group col xs-12 md-3 flex align-center'>
                        <FaHeartbeat className='icons' />
                        <p className='content'>{t('home.benefits.Heart')}</p>
                    </div>
                    <div className='benefits-content-group col xs-12 md-3 flex align-center'>
                        <FaBone className='icons' />
                        <p className='content'>{t('home.benefits.Bone')}</p>
                    </div>
                    {/*                     <div className='benefits-content-group col xs-12 flex align-center'>
                        <FaPlus className='icons' />
                        <p className='content'>{t('home.benefits.And more')}</p>
                    </div> */}
                </section>

                <section className='products row'>
                    {/*                     <div className='col xs-12 flex '>
                        <FiSun size={50} style={{ transform: `translateX(${Y < 1200 ? 0 : Y - 1200}px)` }} />
                    </div> */}
                    {
                        isMobile ? (
                            <div className='col xs-12 md-6' >
                                <Parallax
                                    strength={200}
                                    style={{ height: '350px' }}
                                    bgImage={'/img/Peaceful.jpeg'}
                                    bgImageStyle={{ marginTop: '10px', height: '500px' }}
                                />
                                {/*                                 <img src='/img/2.png' alt='' className='img-p day-night' />
                                <img src='/img/3.png' alt='' className='img-p day-night' /> */}
                            </div>
                        ) : ''
                    }
                    <div className='col xs-12 md-6 flex justify-center'>
                        <h1 className='title'>{t('home.products.title1-1')} <br /> {t('home.products.title1-2')}</h1>
                        <p className='sub-content'>
                            {t('home.products.content1')}
                        </p>
                        <Button title='See product' path='/products' />
                    </div>
                    {
                        !isMobile ? (
                            <div className='col xs-12 md-6' style={{ padding: 40 }}>
                                <Parallax
                                    strength={200}
                                    style={{ height: '100%' }}
                                    bgImage={'/img/Peaceful.jpeg'}
                                    bgImageStyle={{ marginTop: '-20px', height: '700px' }}
                                />
                                {/*                                 <img src='/img/2.png' alt='' className='img-p day-night' />
                                <img src='/img/3.png' alt='' className='img-p day-night' /> */}
                            </div>
                        ) : ''
                    }
                    {/*                     <div className='col xs-12 flex align-end'>
                        <FiMoon size={50} style={{ transform: `translateX(${Y < 1800 ? 0 : -Y + 1800}px)` }} />
                    </div> */}

                </section>

                <section className='products row'>
                    <div className='col xs-12 md-6' style={{ padding: isMobile ? 0 : 40 }}>
                        <Parallax
                            strength={200}
                            style={{ height: isMobile ? '350px' : '100%' }}
                            bgImage={'/img/SportsBoth.jpg'}
                            bgImageStyle={{
                                marginTop: isMobile ? '100px' : '110px',
                                height: isMobile ? '450px' : '600px',
                            }}
                        />
                    </div>
                    <div className='col xs-12 md-6 flex justify-center'>
                        <h1 className='title'>{t('home.products.title2')}</h1>
                        <p className='sub-content'>
                            {t('home.products.content2')}
                        </p>
                        <Button title='See product' path='/products' />
                    </div>
                </section>
                <section className='products row'>
                    {
                        isMobile && (
                            <div className='col xs-12 md-6' style={{ padding: 0 }}>
                                <Parallax
                                    strength={200}
                                    style={{ height: '350px' }}
                                    bgImage={'/img/Skin.jpeg'}
                                    bgImageStyle={{ marginTop: 0, height: '500px' }}
                                />
                                {/*                         <img src='/img/Skin.jpeg' alt=''/> */}
                            </div>
                        )
                    }
                    <div className='col xs-12 md-6 flex justify-center'>
                        <h1 className='title'>{t('home.products.title3')}</h1>
                        <p className='sub-content'>
                            {t('home.products.content3')}
                        </p>
                        <Button title='See product' path='/products' />
                    </div>
                    <div className='col xs-12 md-6' style={{ padding: 40 }}>
                        <Parallax
                            strength={200}
                            style={{ height: '100%' }}
                            bgImage={'/img/Skin.jpeg'}
                            bgImageStyle={{ marginTop: '70px', height: '700px' }}
                        />
                        {/*                         <img src='/img/Skin.jpeg' alt=''/> */}
                    </div>
                </section>

                <section className='testimonials row'>
                    <div className='col xs-12 md-12'>
                        <h1 className='title'>{t('home.testimonials.title')}</h1>
                    </div>
                    <div className='col md-5'></div>
                    <div className='col xs-12 md-6 flex column align-center justify-center'>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial1')}</h1>
                            <h4 className='author'>-Toña Espinosa de Ruiz</h4>
                        </span>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial2')}</h1>
                            <h4 className='author'>-Yris Montes</h4>
                        </span>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial3')}</h1>
                            <h4 className='author'>-Levi Vázquez</h4>
                        </span>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial4')}</h1>
                            <h4 className='author'>-Ricardo Herrera</h4>
                        </span>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial2')}</h1>
                            <h4 className='author'>-Yris Montes</h4>
                        </span>
                        <span>
                            <h1 className='testimonial'>{t('home.testimonials.testimonial3')}</h1>
                            <h4 className='author'>-Levi Vázquez</h4>
                        </span>
                    </div>
                </section>
                <div id='bottom'></div>
            </div>
        </div>

    )
}

export default withNamespaces()(withRouter(Home));