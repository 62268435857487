import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Home from './Home/Home';
import Products from './Products/Products'
import Story from './Story/Story'

import NavBar from './Common/NavBar'
import Footer from './Common/Footer'

const Routes = ({ history }) => {
    return (
        <>
            <NavBar />
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/products' component={Products} />
                <Route exact path='/our-story' component={Story} />
            </Switch>
            <Footer/>
        </>
    )
}

export default withRouter(Routes)