import React, { useEffect } from 'react';
import Routes from './components/Routes'
import { BrowserRouter as Router } from 'react-router-dom'

import ReactGA from 'react-ga'

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-127036915-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
    };
  }, [])

  return (
    <div >
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
