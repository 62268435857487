export const tinctures = [
    {
        name: 'CBD Tincture 300',
        description: 'products.tincture300Desc',
        size:'300mg',
        price:{
            'en':65.00,
            'es':1600.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/1.png',
        bg:'bg-low-product'
    },
    {
        name: 'CBD Tincture 500',
        description: 'products.tincture500Desc',
        size:'500mg',
        price:{
            'en':100.00,
            'es':2200.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/2.png',
        bg:'bg-medium-product'
    },
    {
        name: 'CBD Tincture 1000',
        description: 'products.tincture1000Desc',
        size:'1000mg',
        price:{
            'en':140.00,
            'es':3000.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/3.png',
        bg:'bg-high-product'
    },
    {
        name: 'CBD Tincture 1500',
        description: 'products.tincture1500Desc',
        size:'1500mg',
        price:{
            'en':180.00,
            'es':4000.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/3.png',
        bg:'bg-low-product'
    },
    {
        name: 'Night Cap',
        description: 'products.tinctureNightCap',
        size:'N/A',
        price:{
            'en':90.00,
            'es':2000.00
        },
        bottleSize:{
            'en':'1 oz',
            'es':'30ml'
        },
        img:'/img/3.png',
        bg:'bg-normal'
    },
]

export const sprays = [
    {
        name: 'Lavander',
        description: 'products.sprayLavander',
        size:'N/A',
        price:{
            'en':45.00,
            'es':1000.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    },
    {
        name: 'Peppermint',
        description: 'products.sprayPeppermint',
        size:'N/A',
        price:{
            'en':45.00,
            'es':1000.00
        },
        bottleSize:{
            'en':'2 oz',
            'es':'60ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    }
]

export const creams = [
    {
        name: 'Cream EO',
        description: 'products.creamEO',
        size:'N/A',
        price:{
            'en':45.00,
            'es':800.00
        },
        bottleSize:{
            'en':'2.5 oz',
            'es':'74ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    },
    {
        name: 'Cream EO',
        description: 'products.creamEO',
        size:'N/A',
        price:{
            'en':60.00,
            'es':'-'
        },
        bottleSize:{
            'en':'4.5 oz',
            'es':'133ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    },
    {
        name: 'Cream',
        description: 'products.cream',
        size:'N/A',
        price:{
            'en':45.00,
            'es':1200.00
        },
        bottleSize:{
            'en':'2.5 oz',
            'es':'74ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    },
    {
        name: 'Cream',
        description: 'products.cream',
        size:'N/A',
        price:{
            'en':60.00,
            'es':'-'
        },
        bottleSize:{
            'en':'4.5 oz',
            'es':'133ml'
        },
        img:'/img/cavida-logo.png',
        bg:''
    }
]

