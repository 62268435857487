import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FiInstagram, FiFacebook } from 'react-icons/fi'
import Input from '../Common/Input'
import { FiArrowRightCircle } from 'react-icons/fi'

import { withNamespaces } from 'react-i18next'
import i18next from 'i18next'
import axios from 'axios'

import FlagIconFactory from 'react-flag-icon-css'

const FlagIcon = FlagIconFactory(React, { useCssModules: false })


const Footer = ({ history, t, lng }) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [X, setX] = useState(window.innerWidth)

    const openWindow = (path) => (e) => {
        e.preventDefault()
        window.open(path)
    }
    const validateEmail = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handleContact = () => {
        axios({
            method: 'POST',
            url: 'https://cavida-back-end.herokuapp.com/register',
            data: {
                email,
                firstName: name
            }
        }).then(res => {
            console.log('Succesfull register', res)
            setEmail('')
            setName('')
        }).catch(err => {
            console.log(err)
            setName('Unable to register')
            setEmail('Contact')
        })
    }

    const goTo = (path) => e => {
        history.push(path)
        window.scrollTo(0, 0)
    }

    const changeLanguage = lang => e => {
        e.preventDefault();
        i18next.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }

    return (
        <footer>
            <div className='container'>
                <div className='row footer-content'>

                    {/* Top Section */}
                    <div className='col xs-12 md-4 title-decoration' />
                    <div className='col xs-12 md-4 flex align-center justify-center'>
                        <img className='logo' src='/img/cavida-logo-white.png' alt='logo' />
                        {/* <h1>CAVIDA</h1> */}
                    </div>
                    <div className='col xs-12 md-4 title-decoration' />


                    {/* Bottom Section */}
                    <div className='col xs-12 md-4 flex align-center link-content'>
                        <div className='flex align-center'>
                            <h5 className='link'>{t('footer.Terms & Conditions')}</h5>
                            <h5 className='link' onClick={goTo('/our-story')}>{t('footer.About')}</h5>
                        </div>
                    </div>
                    {
                        X <= 576 &&
                        <div className='col xs-12 md-4 flex align-center link-content'>
                            <div className='flex align-center'>
                                <h5 className='link'>{t('footer.Store')}<sub>{t('footer.Soon')}</sub></h5>
                            </div>
                            <div className='flex flex-direction-row align-center'>
                                <h5 className='margin-right-10 link'>{t('footer.Lang')}: </h5>
                                <h5 className={`link margin-right-6 ${lng === 'en' ? 'active' : ''}`} onClick={changeLanguage('en')}>
                                    <FlagIcon code='us' />
                                </h5>
                                |
                                <h5 className={`link margin-left-10 ${lng === 'es' ? 'active' : ''}`} onClick={changeLanguage('es')}>
                                    <FlagIcon code='mx' />
                                </h5>
                            </div>
                        </div>
                    }
                    <div className='col xs-12 md-4 flex align-center justify-center'>
                        <div className='social-icons'>
                            <FiFacebook className='pointer icons' onClick={openWindow('https://www.facebook.com/cavidacbd')} />
                            <FiInstagram className='pointer icons' onClick={openWindow('https://www.instagram.com/cavidacbd/')} />
                        </div>
                        <span className='social-decoration' />
                        <div className='footer-contact flex align-center justify-center'>
                            <h2 className='margin-bottom-10'>{t('footer.Contact us')}</h2>
                            <div className='flex flex-direction-column align-center justify-center'>
                                <Input placeholder={t('footer.Name')} value={name} className='margin-bottom-20 active' onChange={(e) => setName(e.target.value)} />
                                <Input placeholder={t('footer.E-Mail')} value={email} className='margin-bottom-20' onChange={(e) => setEmail(e.target.value)} />
                                <FiArrowRightCircle className={`pointer padding-bottom-20 send-btn ${validateEmail() ? 'active' : ''}`} onClick={handleContact} />
                            </div>
                        </div>
                    </div>
                    {
                        X > 576 &&
                        <div className='col xs-12 md-4 flex align-center link-content'>
                            <div className='flex align-center'>
                                <h5 className='link'>{t('footer.Store')}<sub>{t('footer.Soon')}</sub></h5>
                            </div>
                            <div className='flex flex-direction-row align-center'>
                                <h5 className='margin-right-10 link'>{t('footer.Lang')}: </h5>
                                <h5 className={`link margin-right-6 ${lng === 'en' ? 'active' : ''}`} onClick={changeLanguage('en')}>
                                    <FlagIcon code='us' />
                                </h5>
                                |
                                <h5 className={`link margin-left-10 ${lng === 'es' ? 'active' : ''}`} onClick={changeLanguage('es')}>
                                    <FlagIcon code='mx' />
                                </h5>
                            </div>
                        </div>
                    }
                </div>

                <div className='row footer-leyends'>
                    <div className=' col xs-12 flex align-center justify-center'>
                        <h5>{t('footer.CopyrightMsg')}</h5>
                    </div>
                    <div className=' col xs-12 flex align-center justify-center'>
                        <h5>{t('footer.FDAMsg')}</h5>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default withNamespaces()(withRouter(Footer))