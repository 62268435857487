import React, { useState, useEffect } from 'react'
import ProductGroup from '../Common/ProductGroup'

import { tinctures, creams, sprays } from '../../data/products'

import { withNamespaces } from 'react-i18next'

const Products = ({ t }) => {

    const [dynamicBg, setDynamicBg] = useState('bg-normal')

    useEffect(() => {
        console.log('simon')
        window.scrollTo(0, 0)
    }, []);

    const callBack = (data) => {
        setDynamicBg(data)
    }

    const scrollToHash = (hash) => e => {
        e.preventDefault()
        const element = document.getElementById(hash)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }
    return (
        <section className={`our-products flex justify-center ${dynamicBg}`}>
            <div className='container'>
                <h1 className='title'>{t('navbar.Products')}</h1>
                <ProductGroup title='Tinctures' data={tinctures} callBack={callBack} buttonProps={{ onClick: scrollToHash('contact') }} />
                <ProductGroup title='Sprays' data={sprays} callBack={callBack} buttonProps={{ onClick: scrollToHash('contact') }} />
                <ProductGroup title='Creams' data={creams} callBack={callBack} buttonProps={{ onClick: scrollToHash('contact') }} />
            </div>
            <div id='contact' />
        </section>
    )
}

export default withNamespaces()(Products);