import React from 'react'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

const Button = ({ history, title, className = '', path = '/', t, ...rest }) => {
    const handleClick = () => {
        history.push(path)
        scrollToHash()

    }
    const scrollToHash = (hash) => e => {
        e.preventDefault()
        const element = document.getElementById(hash)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    }

    return (
        <button className={`btn ${className}`} onClick={handleClick} {...rest} >
            {t(`buttons.${title}`)}
        </button>
    )
}

export default withNamespaces()(withRouter(Button));