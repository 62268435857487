import React, { useState, useEffect } from 'react'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import Button from '../Common/Button'

import { withNamespaces } from 'react-i18next'

const ProductGroup = ({ id = '', title, data, onMouseEnter, callBack, buttonProps, t, lng, ...rest }) => {

    const [counter, setCounter] = useState(0)
    const [dinamicBg, setDinamicBg] = useState(0)

    useEffect(() => {
        if (callBack) callBack(dinamicBg)
    }, [counter, dinamicBg]);

    const handleDir = (isRight = true) => (e) => {
        if (isRight && counter < data.length - 1) {
            setCounter(counter + 1)
            setDinamicBg(data[counter + 1].bg)
        }
        if (!isRight && counter > 0) {
            setCounter(counter - 1)
            setDinamicBg(data[counter - 1].bg)
        }
    }

    const { name, size, description, price, img, bottleSize, bg } = data[counter]
    const imgStyle = img.includes('logo') ? { width: 250 } : {}
    return (
        <div id={id} className='row'>
            <div className='col xs-12'>
                <div className='product-group-card row' onMouseEnter={() => setDinamicBg(bg)} onMouseLeave={() => setDinamicBg('bg-normal')} callBack={callBack} {...rest}>
                    <span className='group-title'>{t(`productCard.${title}`)} - {data.length}</span>
                    <div className='product-img col xs-12 md-4 flex align-center justify-center'>
                        <img src={img} alt='' style={imgStyle} />
                        {
                            img.includes('logo') && (
                                <span>{t('generic.no-image')}</span>
                            )
                        }
                    </div>
                    <div className='product-content col xs-12 md-8 flex justify-center'>
                        <h1>{name}</h1>
                        <h2>{size} - {bottleSize[lng]}</h2>
                        <h1>${price[lng]} {lng === 'en' ? 'DLL' : 'MXN'}</h1>
                        <p style={{ textAlign: 'justify' }}>
                            {t(description)}
                        </p>
                        <Button title='Contact' path='contact' {...buttonProps} />
                    </div>
                    <div className='col xs-12 flex flex-direction-row align-center justify-center margin-top-20'>
                        <FiChevronLeft size={30} className='pointer margin-right-50' onClick={handleDir(false)} />
                        <FiChevronRight size={30} className='pointer' onClick={handleDir(true)} />
                    </div>
                    <span className='group-title'><small style={{ fontSize: '14px' }}>{counter + 1}</small> - {title}</span>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(ProductGroup);